import aboutUsArrow from '../assets/img/aboutUsArrow.svg'

const AboutUs = () => {
  return (
    <div className='containerMainElements' >
      <div className='aboutUs' id='aboutUs'>
        <div className='aboutUsTitile'>
          <img src={aboutUsArrow}/>
          <h2>Так кто же мы такие?</h2>
        </div>
        <p>
          Это динамично развивающаяся IT - компания с широким спектром услуг в области 
          разработки программного обеспечения. Наши эксперты имеют богатый опыт работы
           с компаниями разных масштабов: от стартапов до крупных корпораций. Наша миссия 
           — создавать продукты, которые эффективно решают проблемы наших клиентов и добавляют
            им конкурентное преимущество.
        </p>
      </div>
    </div>
  )
}
export default AboutUs