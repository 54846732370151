import React,{ useState, useRef  } from 'react';

import emailjs from '@emailjs/browser';
import letsWork from '../assets/img/letsWork.svg'
import letsWorkArrow from '../assets/svg/letsWorkArrow.svg'
import headerArrow from '../assets/img/headerArrow.svg'
import sendEmail from '../utils/sendForm';

const LetsDealForm = ({setFeedbackPopupActive}) => {
  const [isArrowButton, setArrowButton] = useState(false);

  const [isName, setName] = useState('');
  const [isPhoneNumber, setPhoneNumber] = useState('');
  const [isEmail, setEmail] = useState('');
  const [isRequired, setRequired] = useState(false)
  const form = useRef();

  const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
  const PHONE_REGEXP = /(\d?)(\d{3})(\d{3})(\d{2})(\d{2})/g;

  const checkForm = async(e) => {
    if(EMAIL_REGEXP.test(isEmail) || PHONE_REGEXP.test(isPhoneNumber)) {
       await sendEmail(e);

       setFeedbackPopupActive(true)

      setName('');
      setPhoneNumber('');
      setEmail('');
      setRequired(false)
    }else {
      setRequired(true)
    }
  }

  return (
    <div className='containerMainElements'>
      <div className='letsWork' id='letsWork'>
        <div className='letsWorkTextContainer'>
          <img src={letsWorkArrow} className='letsWorkArrow'/>
          <h2>Давайте начнем работу прямо сейчас!</h2>
          <p>Запишитесь на бесплатную часовую консультацию</p>
          <img src={letsWork} className='letsWorkImg'/>
        </div>
        <div className='letsWorkFormContainer'>
          <form 
            ref={form}
            className='letsWorkForm' 
            method="post" 
            encType="multipart/form-data"
            onSubmit={(e) => {
              e.preventDefault()
              checkForm(e)
              }
            }
          >
            <input 
              tabIndex={1}
              className='letsWorkFormInput'
              placeholder='Ваше имя'
              name='currentUserName'
              type='text'
              value={isName}
              onChange={(e) => setName(e.target.value)}
            />
            <input 
              tabIndex={2}
              className={`letsWorkFormInput ${isRequired? 'required' : ''}`}
              placeholder='Ваш номер телефона'
              name='currentUserPhone'
              type='tel'
              value={isPhoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              // required
            />
            <input 
              tabIndex={3}
              className={`letsWorkFormInput ${isRequired? 'required' : ''}`}
              placeholder='Ваш email'
              name='currentUserEmail'
              type='email'
              value={isEmail}
              onChange={(e) => setEmail(e.target.value)}
            />
            <p className='privitePolicy'>
              Нажимая на кнопку «Создать свое приложение» Вы соглашаетесь с  
              <a href='"https://easytechapps.com/personal_data_processing.pdf"' className='privitePolicyLink'> Политикой конфиденциальности</a> нашей компании.
            </p>
            <button 
              tabIndex={4} 
              className='letsWorkFormButton' 
              type="submit"
              value="Send"
              onMouseOver={() => setArrowButton(true)} 
              onMouseOut={() => setArrowButton(false)}
            >
              Создать свое приложение 
              <div className={`FormArrowContainer ${isArrowButton? 'btnArrowLetsDeal' : ''}`}><img src={headerArrow}/></div></button>
          </form>
        </div>
      </div>
    </div>
  )
}
export default LetsDealForm