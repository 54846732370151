import Sticky from 'react-sticky-el';
import { useState } from 'react'
import SideBar from './SideBar';
import scrollLink from '../utils/scrollLink';

import logo from '../assets/img/logo.svg'

const NavBar = () => {
  const [isSticky, setSticky] = useState(false)
  const [bmIsActive, bmSetActive] = useState(false)

  const updateBmSetActive = (value) => {
    bmSetActive(value)
  }
  scrollLink()
  return (
    <>
      <div className='stickyContainer'>
        <Sticky 
          onFixedToggle = {(el) => setSticky(el)}
          topOffset={0}
        >
          <header className={`header ${(isSticky)? 'headersticky' : ''} `}>
            <div className='headerLogo'>
              <img src={logo} />
            </div>
            <nav className='headerNav'>
              <a href='#main' className='headerNavElement scaleElement' >Главная</a>
              <a href='#aboutUs' className='headerNavElement scaleElement'>О нас</a>
              <a href='#service' className='headerNavElement scaleElement'>Услуги</a>
              <a href='#stageWork' className='headerNavElement scaleElement'>Этапы</a>
              <a href='#contacts' className='headerNavElement scaleElement'>Контакты</a>
            </nav>
              <a href='#letsWork' className='headerNavButtonToForm elementToOrange'>
                Бесплатная консультация
              </a>
              <a href='tel:+375299991309' className='headerNavButtonToCall'>
                Позвонить
              </a>
              <div 
                className={`headerBurger ${bmIsActive? 'active': ''}`}
                onClick={() => bmSetActive(!bmIsActive)}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
          </header>
        </Sticky>
      </div>
        
      <header className='headerMobile'>
        <div className='headerMobileContent'>
          <div className='headerLogo'>
            <img src={logo} />
          </div>
          <div 
            className={`headerBurger ${bmIsActive? 'active': ''}`}
            onClick={() => bmSetActive(!bmIsActive)}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <SideBar bmIsActive ={bmIsActive} updateBmSetActive={updateBmSetActive}/>
        </div>
        
      </header>
    </>
  )
}
export default NavBar