import emailjs from '@emailjs/browser';

const sendEmail = (e) => {
    return new Promise((resolve, reject) => {
        emailjs.sendForm('service_fui88bq', 'template_asfsoeo', e.target, 'ErgJUtZ8ayEKOFi0Z')
            .then((result) => {
                resolve(result.text)
            }, (error) => {
                reject(error.text)
            });
    })
};
export default sendEmail