import React, {useState} from 'react'
import NavBar from './components/NavBar';
import Header from './components/Header';
import BusinessEffective from './components/BusinessEffective';
import AboutUs from './components/AboutUs';
import OurServises from './components/OurServises';
import StagesOfWork from './components/StagesOfWork';
import LetsDealForm from './components/LetsDealForm';
import Footer from './components/Footer';
import FeedbackPopup from './components/FeedbackPopup';

import './App.css';

function App() {
  const [isFeedbackPopupActive, setFeedbackPopupActive] = useState(false);

  return (
    <div className='lendingContainer' id='lendingContainer'>
      <NavBar />
      <Header />
      <main className='main' id='page-wrap'>
        <BusinessEffective />
        <AboutUs />
        <OurServises setFeedbackPopupActive={setFeedbackPopupActive} />
        <StagesOfWork />
        <LetsDealForm setFeedbackPopupActive={setFeedbackPopupActive} />
      </main>

      <FeedbackPopup updateActive={setFeedbackPopupActive} isActive={isFeedbackPopupActive}/>
      <Footer />
    </div>
  );
}

export default App;
