import { useRef, useState } from "react"
import popupEsc from '../assets/svg/popapEsc.svg'

const FeedbackPopup = ({isActive, updateActive}) => {
  const popupContainer = useRef(null);
  const popupExit = useRef(null);
  
  return(
  <div 
    ref={popupContainer}
    className={`feedbackPopupContainer ${isActive? 'open' : ''}`}
    onClick={(e) => {(e.target === popupContainer.current || e.target === popupExit.current)? updateActive(false): updateActive(true)}}
  >
    <div className="feedbackPopupContent">
      <a className="feedbackPopupCloseForm">
        <img ref={popupExit} src={popupEsc} alt="feedbackPopupCloseForm" />
      </a>
      <h2>
        Спасибо за Вашу заявку
      </h2>
      <h3>
        Наши специалисты свяжутся с Вами в ближайшее время
      </h3>
    </div>
  </div>
  )
}
export default FeedbackPopup