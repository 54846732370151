import { useState } from 'react'

import headerArrow from '../assets/img/headerArrow.svg'
import line from "../assets/svg/Line 71.svg"
import inst from '../assets/svg/linkINST.svg'
import likedIn from '../assets/svg/linkLINKKEEIN.svg'
import facebook from '../assets/svg/linkFACEBOOK.svg'

const Header = () => {
  const [isButtonActive, setButtonActive] = useState(false)

  return (
    <div className='headerContainer' id='main'>
      <div className='ellipse'></div>
      <div className='ellipse2'></div>
      <p className='headerFallEl selectBlockText'>Consultation services</p>
      <p className='headerFallEl selectBlockText'>Data analytics</p>
      <p className='headerFallEl selectBlockText'>Business solutions</p>
      <p className='headerFallEl selectBlockText'>UX/UI design</p>
      <p className='headerFallEl selectBlockText'>Web development</p>
      <p className='headerFallEl selectBlockText'>Mobile app development</p>
      <div className='headerSocialLink'>
        <img src={line} alt='line'/>
        <a href='https://www.facebook.com/easytechapps?mibextid=ZbWKwL'><img src={facebook} alt="Facebook Link" /></a>
        <a href='https://instagram.com/easytechapps?igshid=OGQ5ZDc2ODk2ZA=='><img src={inst} alt="Instagram Link" /></a>
        <a href='https://www.linkedin.com/company/easytechapps/'><img src={likedIn} alt="LinkedIn Link" /></a>
      </div>
      <div className='headerMobileDev' >
        <h1>Разработка мобильных приложений под ключ</h1>
        <p >Узнайте как мобильное приложение увеличит ваши продажи и лояльность клиентов</p>
        <a href='#letsWork' onMouseOver={() => setButtonActive(true)} onMouseOut={() => setButtonActive(false)}>
          Бесплатная консультация <div className={`headerArrowContainer ${isButtonActive? 'hover' : ''}`}><img src={headerArrow}/></div>
        </a>
      </div>
    </div>
  )
}
export default Header