import { useInView } from 'react-intersection-observer';
import { useState } from 'react';
import PopupMessage from './Popup';

const Service = ({setFeedbackPopupActive}) => {
  const [ref1, inView1] = useInView ({
    threshold: 0.3,
    // triggerOnce: true,
  })
  const [ref2, inView2] = useInView ({
    threshold: 0.3,
    // triggerOnce: true,
  })
  const [ref3, inView3] = useInView ({
    threshold: 0.3,
    // triggerOnce: true,
  })
  const [isActive, setActive] = useState(false)
  const updateActive = (value) => {
    setActive(value)
  }

  return (
    <div className='containerMainElements'>
      <div className='service' id='service'>
        <h2>Наши услуги</h2>
        <div className='serviceFlex'>
          <div 
            ref={ref1} 
            className={`serviceCard ${inView1? 'serviceCardSlideEffect1' : ''}`}
          >
            <p className='serviceCardTime selectBlockText'>30 - 60 минут</p>
            <h3>Бесплатная консультация</h3>
            <ul className='serviceCardList'>
              <li>Анализ текущего мобильного рынка и трендов</li>
              <li>Рекомендации по созданию и/или оптимизации мобильного приложения</li>
              <li>Оценка потребностей и возможностей для внедрения новыйх мобильных решений</li>
              <li>Составление плана действий для запуска мобильного проекта</li>
            </ul>
            <button 
              className='serviceCardButton'
              onClick={(e) => setActive(true)}
            >
              Оставить заявку
            </button>
          </div>
          <div 
            ref={ref2} 
            className={`serviceCard ${inView2? 'serviceCardSlideEffect2' : ''}`}
          >
            <p className='serviceCardTime selectBlockText'>7 - 10 дней</p>
            <p className='serviceCardBestВecision selectBlockText'>Оптимальное решение</p>
            <h3>Макет+функционал</h3>
            <ul className='serviceCardList'>
              <li>Анализ требований к  мобильному приложению</li>
              <li>Проектирование интерфейса для мобильных устройств</li>
              <li>Планирование и описание функциональных требований</li>
              <li>Оценка трудоёмкости и ресурсов для мобильной разработки</li>
            </ul>
            <button 
              className='serviceCardButton'
              onClick={() => setActive(true)}
            >
              Оставить заявку
            </button>
          </div>
          <div 
            ref={ref3} 
            className={`serviceCard ${inView3? 'serviceCardSlideEffect3' : ''}`}
          >
            <p className='serviceCardTime selectBlockText'>10 -15 дней</p>
            <h3>Макет+функионал+ техническое задание</h3>
            <ul className='serviceCardList'>
              <li>Глубокий анализ требований к продвинутому приложению</li>
              <li>Проектирование сложного интерфейса с учетом последних тенденций дизайна</li>
              <li>Создание полноразмерного технического задания для разработки приложения</li>
              <li>Планирование продвинутых функций и их взаимодействия <br /> в приложении</li>
            </ul>
            <button 
              className='serviceCardButton'
              onClick={() => setActive(true)}
            >
              Оставить заявку
            </button>
          </div>
        </div>
      </div>
      <PopupMessage updateActive={updateActive} isActive={isActive} onMessageSent={setFeedbackPopupActive}/>
    </div>
  )
}
export default Service