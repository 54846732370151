import scrollLink from '../utils/scrollLink'

import inst from '../assets/svg/linkINST.svg'
import likedIn from '../assets/svg/linkLINKKEEIN.svg'
import facebook from '../assets/svg/linkFACEBOOK.svg'
import logo from '../assets/img/logo.svg'


const Footer = () => {
  scrollLink()
  return (
    <>
      <footer className='footer' id='contacts'>
        <div className='footerINC'>
          <img src={logo} className='footerLogo'/>
          <div>
            <p>ООО “ИЗИ ТЕХ СОЛЮШЕНС”</p>
            <p>УНП 692216268</p>
            <a className='elementToOrange' href="https://easytechapps.com/personal_data_processing.pdf">Политика конфиденциальности</a>
          </div>
        </div>
        <nav className='footerNav'>
          <a href='#main' className='elementToOrange'>Главная</a>
          <a href='#service' className='elementToOrange'>Услуги</a>
          <a href='#aboutUs' className='elementToOrange'>О нас</a>
          <a href='#stageWork' className='elementToOrange'>Этапы работы</a>
        </nav>
        <div className='footerContact'>
          <h4>Контакты</h4>
          <a href='tel:+375445362708' className='elementToOrange'>+375445362708</a>
          <a href='tel:+375299991309' className='elementToOrange'>+375299991309</a>
          <a href='mailto:info@easytechapps.com' className='elementToOrange '>info@easytechapps.com</a>
        </div>
        <div className='footerSocialWeb'>
          <h4>Соц. сети</h4>
          <div className='footerSocialWeb_Flex'>
            <a href='https://instagram.com/easytechapps?igshid=OGQ5ZDc2ODk2ZA=='><img src={inst}/></a>
            <a href='https://www.linkedin.com/company/easytechapps/'><img src={likedIn}/></a>
            <a href='https://www.facebook.com/easytechapps?mibextid=ZbWKwL'><img src={facebook}/></a>
          </div>
          
        </div>
      </footer>

      <footer className='footerMobile' id='footerMobile'>
        <img src={logo} className='footerLogo'/>
        <div className='footerMobileFlex'>
          <div className='footerContact'>
            <h4>Контакты</h4>
            <a href='tel:+375445362708' className='elementToOrange'>+375445362708</a>
            <a href='tel:+375299991309' className='elementToOrange'>+375299991309</a>
            <div className='footerSocialWeb_Flex'>
              <a href='https://instagram.com/easytechapps?igshid=OGQ5ZDc2ODk2ZA=='><img src={inst}/></a>
              <a href='https://www.linkedin.com/company/easytechapps/'><img src={likedIn}/></a>
              <a href='https://www.facebook.com/easytechapps?mibextid=ZbWKwL'><img src={facebook}/></a>
            </div>
              <a href='mailto:info@easytechapps.com' className='elementToOrange '>info@easytechapps.com</a>
          </div>
          <nav className='footerNav'>
            <a href='#main' className='elementToOrange'>Главная</a>
            <a href='#service' className='elementToOrange'>Услуги</a>
            <a href='#aboutUs' className='elementToOrange'>О нас</a>
            <a href='#stageWorkMobile' className='elementToOrange'>Этапы</a>
          </nav>
        </div>
        <div className='footerINC'>
          <p>ООО “ИЗИ ТЕХ СОЛЮШЕНС”   2023</p>
          <p>УНП 692216268</p>
          <a className='elementToOrange' href="https://easytechapps.com/personal_data_processing.pdf">Политика конфиденциальности</a>
        </div>
      </footer>
    </>
  )
}
export default Footer