import { useInView } from 'react-intersection-observer'
import loyalty from '../assets/img/loyalty.svg'
import marketing from '../assets/img/marketing.svg'
import analytics from '../assets/img/analytics.svg'

const BusinessEffective = () => {
  const [ref1, inView1] = useInView ({
    threshold: 0.3,
    // triggerOnce: true,
  })
  const [ref2, inView2] = useInView ({
    threshold: 0.3,
    // triggerOnce: true,
  })
  const [ref3, inView3] = useInView ({
    threshold: 0.3,
    // triggerOnce: true,
  })
  return (
    <div className='containerMainElements'>
      <div className='businessEff'>
        <h2>Как мобильное приложение поможет увеличить эффективность бизнеса?</h2>
        <div className='businessEffFlex'>
          <div ref={ref1} className={`businessEffCard ${inView1? 'businessEffCardSlideEffect1' : ''}`}>
            <h3>Увеличение лояльности</h3>
              <img src={loyalty}/>
              <p className='businessEffCardText'>
                После установки приложения, клиент, с большей вероятностью вернется к вам снова и снова, тем самым "привязываясь" к вам. Соответственно, вы увеличиваете повторные продажи, получая базу лояльных клиентов
              </p>
          </div>
          <div ref={ref2} className={`businessEffCard ${inView2? 'businessEffCardSlideEffect2' : ''}`}>
            <h3>Эффективный маркетинг</h3>
              <img src={marketing}/>
              <p className='businessEffCardText2'>
                Мобильное приложение не только повышает имидж вашего бренда и выделяет вас среди конкурентов, но и позволяет быть с покупателем на расстоянии вытянутой руки, отправляя уведомления о специальных предложениях и акциях
              </p>
          </div>
          <div ref={ref3} className={`businessEffCard ${inView3? 'businessEffCardSlideEffect3' : ''}`}>
            <h3>Статистика и аналитика</h3>
              <img src={analytics}/>
              <p className='businessEffCardText'>
                Вы сможете собирать данные о предпочтениях вашей аудитории, чтобы понимать ее настроение и создавать лучший клиентский сервис на рынке
              </p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default BusinessEffective