import { useRef, useState } from "react"
import headerArrow from '../assets/img/headerArrow.svg'
import popupEsc from '../assets/svg/popapEsc.svg'
import sendEmail from "../utils/sendForm"

const PopupMessage = ({isActive, updateActive, onMessageSent}) => {
  const popupContainer = useRef(null);
  const popupExit = useRef(null);
  const [isArrowButton, setArrowButton] = useState(false);

  const [isName, setName] = useState('');
  const [isPhoneNumber, setPhoneNumber] = useState('');
  const [isEmail, setEmail] = useState('');
  const [isRequired, setRequired] = useState(false)

  const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
  const PHONE_REGEXP = /(\d?)(\d{3})(\d{3})(\d{2})(\d{2})/g;
  
  const checkForm = async(e) => {
    if(EMAIL_REGEXP.test(isEmail) || PHONE_REGEXP.test(isPhoneNumber)) {
      await sendEmail(e);

      updateActive(false);
      onMessageSent(true);
      setName('');
      setPhoneNumber('');
      setEmail('');
      setRequired(false)
    } else {
      setRequired(true)
    }
  }
  
  return(
  <div 
    ref={popupContainer}
    className={`popupContainer ${isActive? 'open' : ''}`}
    onClick={(e) => {
      if(e.target === popupContainer.current || e.target === popupExit.current) {
        updateActive(false)
      }
    }}
  >
    <div className="popupContent">
      <a className="popupCloseForm">
        <img ref={popupExit} src={popupEsc} alt="popupCloseForm" />
      </a>
      <p className="popupContentTitle">
        Оставьте заявку на бесплатную консультацию и мы свяжемся с вами!
      </p>
      <form 
        className='popupForm' 
        method="post" 
        encType="multipart/form-data"
        onSubmit={(e) => {
          e.preventDefault();
          checkForm(e)
          }
        }
      >
        <input 
          tabIndex={1}
          className='popupFormInput'
          placeholder='Ваше имя'
          name='currentUserName'
          type='text'
          value={isName}
          onChange={(e) => setName(e.target.value)}
        />
        <input 
          tabIndex={2}
          className={`popupFormInput ${isRequired? 'required' : ''}`}
          placeholder='Ваш номер телефона'
          name='currentUserPhone'
          type='tel'
          value={isPhoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <input 
          tabIndex={3}
          className={`popupFormInput ${isRequired? 'required' : ''}`}
          placeholder='Ваш email'
          name='currentUserEmail'
          type='email'
          value={isEmail}
          onChange={(e) => setEmail(e.target.value)}
        />
        <p className='popupPrivitePolicy'>
          Нажимая на кнопку «Создать свое приложение» Вы соглашаетесь с  
          <a href='"https://easytechapps.com/personal_data_processing.pdf"' className='popupPrivitePolicyLink'> Политикой конфиденциальности</a> нашей компании.
        </p>
        <button 
          tabIndex={4} 
          className='popupFormButton'
          type="submit"
          value="Send"
          onMouseOver={() => setArrowButton(true)} 
          onMouseOut={() => setArrowButton(false)}
        >
          Создать свое приложение 
          <div className={`popupFormArrowContainer ${isArrowButton? 'btnArrowPopup' : ''}`}><img src={headerArrow}/></div>
        </button>
      </form>
    </div>
  </div>
  )
}
export default PopupMessage