import { useInView } from 'react-intersection-observer'

const StagesOfWork = () => {
  // const ref = useRef();
  const [ref1, inView1] = useInView ({threshold: 0.5,})
  const [ref2, inView2] = useInView ({threshold: 0.5,})
  const [ref3, inView3] = useInView ({threshold: 0.5,})
  const [ref4, inView4] = useInView ({threshold: 0.5,})
  const [ref5, inView5] = useInView ({threshold: 0.5,})
  const [ref6, inView6] = useInView ({threshold: 0.5,})
  const [ref7, inView7] = useInView ({threshold: 0.5,})
  const [ref11, inView11] = useInView ({threshold: 0.5,})
  const [ref21, inView21] = useInView ({threshold: 0.5,})
  const [ref31, inView31] = useInView ({threshold: 0.5,})
  const [ref41, inView41] = useInView ({threshold: 0.5,})
  const [ref51, inView51] = useInView ({threshold: 0.5,})
  const [ref61, inView61] = useInView ({threshold: 0.5,})
  const [ref71, inView71] = useInView ({threshold: 0.5,})

  return (
    <div className='containerMainElements' >
      <div className='stageWork' id='stageWork'>
        <h2>Этапы работы</h2>
        <div className='stageWorkFlex'>
          <div ref={ref1} className={`stageCard ${inView1? 'stageCardSlideEffectRtoL' : ''}`}>
            <p className='stageStep'>1. Проводим консультацию</p>
            <p className='stageStepText stageStepText1'>Полностью погружаемся в ваш бизнес, определяем вектор развития и целесообразность создания мобильного приложения для вашей компании</p>
          </div>
          <div ref={ref2} className={`stageCard ${inView2? 'stageCardSlideEffectLtoR' : ''}`}>
            <p className='stageStep'>2. Делаем макет+функционал+ТЗ</p>
            <p className='stageStepText stageStepText2'>Разработка макета вашего приложения с описанием всех функций и работы с технической точки зрения</p>
          </div>
          <div ref={ref3} className={`stageCard ${inView3? 'stageCardSlideEffectRtoL' : ''}`}>
            <p className='stageStep'>3. Редактируем и согласовываем будущее приложение</p>
            <p className='stageStepText stageStepText3'>На этом этапе мы презентуем вам макет, получаем обратную связь, в случае необходимости, корректируем дизайн и ТЗ + рассчитываем сроки и стоимость реализации приложения</p>
          </div>
          <div ref={ref4} className={`stageCard ${inView4? 'stageCardSlideEffectRtoL' : ''}`}>
            <p className='stageStep'>4. Все готово к старту - начинаем разработку :)</p>
            <p className='stageStepText stageStepText4'>Предоставляем вам еженедельный отчет о проделанной работе</p>
          </div>
          <div ref={ref5} className={`stageCard ${inView5? 'stageCardSlideEffectLtoR' : ''}`}>
            <p className='stageStep'>5. Публикуем пилотную версию вашего приложения</p>
            <p className='stageStepText stageStepText5'>Обычно первая версия содержит минимально необходимый функционал, что позволяет сразу получать обратную связь от ваших клиентов, экономя время и бюджет разработки</p>
          </div>
          <div ref={ref6} className={`stageCard ${inView6? 'stageCardSlideEffectRtoL' : ''}`}>
            <p className='stageStep'>6. Дополнение и улучшение</p>
            <p className='stageStepText stageStepText6'>Продолжаем разработку, а затем публикацию финальной версии приложения, радуя уже подключившихся пользователей новым функционалом, а вас - преимуществами приложения</p>
          </div>
          <div ref={ref7} className={`stageCard ${inView7? 'stageCardSlideEffectLtoR' : ''}`}>
            <p className='stageStep'>7. Техническая поддержка</p>
            <p className='stageStepText stageStepText7'>Берем приложение на техническую поддержку и дальнейшее развитие новых функций</p>
          </div>
        </div>
      </div>

      <div className='stageWorkMobile' id='stageWorkMobile'>
        <h2>Этапы работы</h2>
        <div className='stageWorkFlex'>
          <div ref={ref11} className={`stageCard ${inView11? 'stageCardSlideEffectRtoL' : ''}`}>
            <p className='stageStep'>1. Проводим консультацию</p>
            <p className='stageStepText stageStepText1'>Полностью погружаемся в ваш бизнес, определяем вектор развития и целесообразность создания мобильного приложения для вашей компании</p>
          </div>
          <div ref={ref21} className={`stageCard ${inView21? 'stageCardSlideEffectLtoR' : ''}`}>
            <p className='stageStep'>2. Делаем макет+функционал+ТЗ</p>
            <p className='stageStepText stageStepText2'>Разработка макета вашего приложения с описанием всех функций и работы с технической точки зрения</p>
          </div>
          <div ref={ref31} className={`stageCard ${inView31? 'stageCardSlideEffectRtoL' : ''}`}>
            <span className='stageStep'>3. Редактируем и согласовываем</span><span className='stageStepToRigth'>будущее приложение</span>
            <p className='stageStepText stageStepText3'>На этом этапе мы презентуем вам макет, получаем обратную связь, в случае необходимости, корректируем дизайн и ТЗ + рассчитываем сроки и стоимость реализации приложения</p>
          </div>
          <div ref={ref41} className={`stageCard ${inView41? 'stageCardSlideEffectRtoL' : ''}`}>
            <span className='stageStep'>4. Все готово к старту —</span><span className='stageStepToRigth'> начинаем разработку :)</span>
            <p className='stageStepText stageStepText4'>Предоставляем вам еженедельный отчет о проделанной работе</p>
          </div>
          <div ref={ref51} className={`stageCard ${inView51? 'stageCardSlideEffectLtoR' : ''}`}>
            <span className='stageStep'>5. Публикуем пилотную версию</span><span className='stageStepToRigth'> вашего приложения</span>
            <p className='stageStepText stageStepText5'>Обычно первая версия содержит минимально необходимый функционал, что позволяет сразу получать обратную связь от ваших клиентов, экономя время и бюджет разработки</p>
          </div>
          <div ref={ref61} className={`stageCard ${inView61? 'stageCardSlideEffectRtoL' : ''}`}>
            <p className='stageStep'>6. Дополнение и улучшение</p>
            <p className='stageStepText stageStepText6'>Продолжаем разработку, а затем публикацию финальной версии приложения, радуя уже подключившихся пользователей новым функционалом, а вас - преимуществами приложения</p>
          </div>
          <div ref={ref71} className={`stageCard ${inView71? 'stageCardSlideEffectLtoR' : ''}`}>
            <p className='stageStep'>7. Техническая поддержка</p>
            <p className='stageStepText stageStepText7'>Берем приложение на техническую поддержку и дальнейшее развитие новых функций</p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default StagesOfWork