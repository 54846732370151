import React from "react";

import logo from '../assets/img/logo.svg'
import inst from '../assets/svg/linkINST.svg'
import likedIn from '../assets/svg/linkLINKKEEIN.svg'
import facebook from '../assets/svg/linkFACEBOOK.svg'
import headerArrow from "../assets/img/headerArrow.svg";

const SideBar = ({bmIsActive, updateBmSetActive}) => {
  return (
    <div className={`bmContainer ${bmIsActive? 'open': ''}`}>
      <img src={logo} alt="logo" className="bmLogo"/>

      <a href='#main' className='bmHeaderNavElement' onClick={() => updateBmSetActive(false)}>Главная</a>
      <a href='#aboutUs' className='bmHeaderNavElement' onClick={() => updateBmSetActive(false)}>О нас</a>
      <a href='#service' className='bmHeaderNavElement' onClick={() => updateBmSetActive(false)}>Услуги</a>
      <a href='#stageWorkMobile' className='bmHeaderNavElement' onClick={() => updateBmSetActive(false)}>Этапы</a>
      <a href='#footerMobile' className='bmHeaderNavElement' onClick={() => updateBmSetActive(false)}>Контакты</a>
      <a href='#letsWork' className='bmHeaderNavButtonToForm' onClick={() => updateBmSetActive(false)}>
          Бесплатная консультация <div className='bmHeaderArrowContainer'><img src={headerArrow}/></div>
        </a>
      <div className='bmHeaderSocialWebFlex'>
        <a href='https://instagram.com/easytechapps?igshid=OGQ5ZDc2ODk2ZA=='><img src={inst}/></a>
        <a href='https://www.linkedin.com/company/easytechapps/'><img src={likedIn}/></a>
        <a href='https://www.facebook.com/easytechapps?mibextid=ZbWKwL'><img src={facebook}/></a>
      </div>
    </div>
  );
};
export default SideBar